@import '../../App.scss';

.container {
    background-color: white;
    height: 100vh;
    border: 1px solid #eaecf0;
    width: 563px;
    -webkit-box-shadow: -5px 0px 30px 10px rgba(0, 0, 0, 0.075);
    box-shadow: -5px 0px 30px 10px rgba(0, 0, 0, 0.075);
    overflow: auto;
}

.header {
    margin: 27px 27px 24px 32px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: black;

    .clientd_id {
        padding-top: 5px;
        font-family: 'Gotham-Book';
        line-height: 13px;
        font-size: 13px;
    }

    .deleted_user {
        margin-left: 15px;
    }

    .close {
        position: absolute;
        right: 0;
        top: 0;
        &:hover {
            cursor: pointer;
            svg path {
                stroke: $couchetard-red;
            }
        }
    }
    .user_info {
        display: flex;
        align-items: center;
        margin-top: 16px;

        :global .ant-avatar {
            border-radius: 50% !important;
        }

        .user_info_and_contact {
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            .user_title {
                font-family: 'Gotham-Black';
                font-size: 24px;
                line-height: 24px;
                display: flex;

                :global .ant-tag {
                    width: 30px;
                    height: 24px;
                    border: none;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 24px;
                    font-size: 13px;
                    font-family: 'Gotham-Book';
                }
            }

            .user_contact {
                font-family: 'Gotham-Book';
                line-height: 13px;
                font-size: 13px;
                margin-top: 9px;
                display: flex;

                .confirmed {
                    margin-left: 6px;
                    margin-top: 2px;
                }

                .phone_number {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .email {
                    margin-left: 16px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
    }
}
