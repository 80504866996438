@import '../../App.scss';

.container {
    padding: 30px;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input {
        width: 322px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.submit_button {
    background-color: $ct-orange;
    margin-top: 20px;
    font-family: 'Gotham-Ultra';
    &:hover {
        background-color: darken($color: $ct-orange, $amount: 10);
    }
}
