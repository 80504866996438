@import '../../App.scss';

.selects_row {
    :global .ant-select,
    :global .ant-input-affix-wrapper {
        margin-right: 16px;
        margin-bottom: 16px;
        width: 200px;
    }
}
