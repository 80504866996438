@import '../../App.scss';

.page_content {
    margin: -16px -16px 0;
    :global .ant-avatar {
        border-radius: 50% !important;
    }

    :global .ant-table-container {
        border-radius: 0px;
    }

    :global .ant-table.ant-table-bordered .ant-table-container {
        border-color: #e6e6e6;
        border-radius: 0px;
        border-left: none;
        border-right: none;
    }

    :global .ant-table-thead .ant-table-cell {
        background-color: #f3f4f5;
        border-color: #e6e6e6;
        color: #6a7581;
    }

    :global .ant-table.ant-table-bordered tbody > tr > td {
        border-right: 1px solid #e6e6e6;
        color: #1d2939;
        font-family: 'Gotham-Book';
        padding: 10px 10px;
    }
    .table_header {
        display: flex;
        align-items: center;
        height: 64px;
        padding: 16px 16px 16px 16px;
        position: relative;
    }
    @media screen and (max-width: 650px) {
        .table_header {
            flex-direction: column;
            height: 120px;
        }
    }

    .pagination_container {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 95%;
        min-width: 460px;
    }

    .query_box {
        width: 400px;
    }
    .create_button {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        border-bottom: 1px solid grey;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}
