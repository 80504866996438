.notification-error {
    .ant-notification-notice-message {
        color: tomato !important;
    }
}

.notification-success {
    .ant-notification-notice-message {
        color: green !important;
    }
}
