.games_content {
    height: 628px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.scroll_content {
    height: 549px;
    width: 100%;
    overflow: auto;
    background-color: white;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll_content::-webkit-scrollbar {
    display: none;
}

.back_arrow {
    position: absolute;
    left: 15px;
    top: 45px;
    height: 20px;
    width: 20px;
}
