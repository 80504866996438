.navbar {
    height: 56px;
    width: 100%;
    background-color: white;
    position: relative;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    -webkit-box-shadow: 0px -6px 10px -6px rgba(0, 0, 0, 0.17);
    box-shadow: 0px -6px 10px -6px rgba(0, 0, 0, 0.17);
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Gotham-Book';
    font-size: smaller;
    svg path {
        fill: #8d8d8d;
    }
    svg {
        height: 20px;
        width: 20px;
    }
}

.icon:hover {
    cursor: pointer;
    color: black;
    svg path {
        fill: red;
    }
}

.icon_activated {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    font-family: 'Gotham-Bold';
    font-size: small;
    svg path {
        fill: red;
    }
    svg {
        height: 20px;
        width: 20px;
    }
}

.icon_deactivated {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Gotham-Book';
    font-size: smaller;
    svg path {
        fill: #8d8d8d;
    }
    svg {
        height: 20px;
        width: 20px;
    }
}

#home {
    grid-column: 1;
    .inkbar {
        position: absolute;
        top: 0;
        width: 73px;
        height: 2px;
        background-color: red;
    }
}

#stores {
    grid-column: 2;
    .inkbar {
        position: absolute;
        top: 0;
        width: 73px;
        height: 2px;
        background-color: red;
    }
}

#promos {
    grid-column: 3;
    #promos_icon {
        transform: rotate(90deg);
    }
    .inkbar {
        position: absolute;
        top: 0;
        width: 73px;
        height: 2px;
        background-color: red;
    }
}

#carwash {
    grid-column: 4;
    .inkbar {
        position: absolute;
        top: 0;
        width: 73px;
        height: 2px;
        background-color: red;
    }
}

#more {
    grid-column: 5;
    .inkbar {
        position: absolute;
        top: 0;
        width: 73px;
        height: 2px;
        background-color: red;
    }
}
