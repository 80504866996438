.store_box {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 12px 24px;
    margin: 12px;
    display: inline-block;
    border-radius: 0.5rem;
}

@mixin selection-icon($color) {
    transform: scale(0.9);
    cursor: pointer;
    position: relative;
    left: 10px;
    transition: color 0.3s;

    &:hover {
        color: $color;
    }
}

.remove_icon {
    @include selection-icon(tomato);
}

.add_icon {
    @include selection-icon(LimeGreen);
}

.dual_box_holder {
    display: flex;
}

.nref_header {
    margin-top: 30px;
    font-family: 'Gotham-Medium';
}

.weekday_label {
    display: inline-block;
    width: 100px;
}

.section_header {
    font-size: 28px;
    position: sticky;
    top: 8rem;
    background-color: white;
    z-index: 10;
    font-family: 'Gotham-Black';
}

.actions {
    padding: 1rem 1rem 0 1rem;
}

.section {
    position: relative;
}

.container {
    height: calc(100vh - 16px);
    overflow-y: scroll;
}

.top_content {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 11;
    padding-bottom: 0.5rem;
}

.deselected {
    opacity: 0.3;
}

.filter {
    width: 300px;
}

@mixin highlight($color) {
    background-color: $color;
    display: inline-block;
    padding: 2px 6px;
    min-width: 10px;
}

.changed {
    @include highlight(#ffe082);
}

.added {
    @include highlight(#a5d6a7);
}

.removed {
    @include highlight(#ef9a9a);
}

.input_row {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    :global .ant-btn.ant-btn-primary {
        background-color: #da291c;
        border-radius: 0.25rem !important;
        width: 10rem;
    }
}

.back_button {
    margin-right: 10px;
    cursor: pointer;
}

.page_header {
    font-family: 'Gotham-Black';
    text-transform: uppercase;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 13px;
    font-size: 13px;
    color: black;
}

.select_buttons {
    max-width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5rem 0;
}

.select_button {
    border-radius: 0.25rem;
    border: 1px solid #e6e6e6;
    background-color: #f3f4f5;
    color: black;
    width: 7rem;
    &:hover {
        cursor: pointer;
    }
    height: 2rem;
    &:active {
        background-color: rgba(218, 41, 28, 0.1);
        color: #da291c;
    }
}
