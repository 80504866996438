:global .ant-collapse {
    border: none;
}

:global .ant-collapse-content {
    border: none;
    padding-bottom: 1rem;
}

:global .ant-collapse-content-box {
    padding: 0 !important;
}

:global .ant-collapse-item {
    border: none !important;
}

.expansion_panel {
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
}
