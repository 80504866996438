@import '../../App.scss';

.line {
    margin-top: 2em;
    margin-bottom: 3.125em;
}

.filters {
    float: left;
    margin-right: 2em;
}

.input_row {
    display: flex;
    padding: 0.25em;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.site_page_header {
    padding: 0;
    font-family: Gotham-Bold;
    font-size: 1.5em;
}

.verticalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f1f2;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    > div {
        margin-right: 0.625em;

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 767px) {
            width: calc(50% - 0.625em);
            margin: 0.3125em auto;
        }

        @media (max-width: 480px) {
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.userAcquisitionContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.page_content {
    margin: -16px -16px 0;
}

.statsRowContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25em;
    justify-content: space-between;
}

.date_picker_header {
    display: flex;
    align-items: center;
    height: 4em;
    padding: 1em;
}

.container {
    padding: 1.25em;
}

.main_container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    @media (max-width: 48em) {
        flex-direction: column;
    }
}

.section_container {
    padding-bottom: 0.9375em;
}

.box_container {
    display: flex;
    flex-direction: column;
    min-width: 18.75em;
    padding-right: 2em;

    .section_title {
        margin-bottom: 0.3125em;
    }

    .row {
        display: flex;
        gap: 0.9375em;

        @media (max-width: 48em) {
            flex-direction: column;
            align-items: center;
        }
    }
}
