@import '../../../../App.scss';

.card_container {
    width: 100%;
    background-color: #ffffff;
    padding: 1rem;
}

.switch_row {
    display: flex;
    justify-content: flex-start;
    gap: 1.3rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
}

.single_toggle {
    display: flex;
    align-items: center;
    gap: 0.35rem;
}

.input_row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media (max-width: $mobile-breakpoint) {
        grid-template-columns: 1fr;
        align-items: flex-start;
    }
}

.slider_container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1rem 0;
}

.gradient_slider {
    width: 100%;
    max-width: 100%;
}

.bold_label {
    font-family: Gotham-Bold;
    font-size: 0.9rem;
    color: #000;
    text-align: left;
}

@media (max-width: $mobile-breakpoint) {
    .slider_container {
        width: 100%;
    }
}
