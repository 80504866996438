.query_box {
    width: 25rem;
}

.pagination_container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-width: 28.75rem;
    padding-right: 4rem;
}

.weekday_label {
    width: 100px;
    display: inline-block;
}

.stores_content {
    margin: -16px -16px 0;
    :global .ant-table-container {
        border-radius: 0px;
    }

    :global .ant-table.ant-table-bordered .ant-table-container {
        border-color: #e6e6e6;
        border-radius: 0px;
        border-left: none;
        border-right: none;
    }

    :global .ant-table-thead .ant-table-cell {
        background-color: #f3f4f5;
        border-color: #e6e6e6;
        color: #6a7581;
    }

    :global .ant-table-tbody > tr > td {
        border-bottom: 1px solid #e6e6e6;
    }
}

.table_header {
    display: flex;
    flex-direction: column;
}

.top_fonctionalities {
    display: flex;
    flex-direction: row;
    padding: 1rem 0 1rem 2rem;
    margin-right: 4rem;
    align-items: center;
    height: 4rem;
    position: relative;
}

.csv_buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 40%;

    :global .ant-btn.ant-btn-primary {
        background-color: #da291c;
        border-radius: 0.25rem !important;
        width: 10rem;
    }
    .export_all {
        border-radius: 0.25rem;
        border: 1px solid #e6e6e6;
        background-color: #f3f4f5;
        color: black;
        width: 10rem;
        &:hover {
            cursor: pointer;
        }
    }
    .loading_csv {
        width: 10rem;
        color: black;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.transparent_button {
    background-color: transparent;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;

    .icon {
        margin-right: 0.25rem;
        height: 1rem;
        width: 1rem;
    }

    &:hover {
        background-color: #f3f4f5;
    }
}

.not_allowed {
    cursor: not-allowed;
    opacity: 0.5;
}

.delete_button {
    color: #da291c;
}

@media screen and (max-width: 1500px) {
    .csv_buttons {
        width: 50%;
    }
}

@media screen and (max-width: 1250px) {
    .top_fonctionalities {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .csv_buttons {
        position: relative;
        width: 100%;
        margin-top: 1rem;
        justify-content: space-between;
    }

    .pagination_container {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 570px) {
    .csv_buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
}

.edit_buttons_container {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #e6e6e6;
    padding: 0.75rem 2rem 0.75rem 2rem;
    height: 3.75rem;

    .edit_store_button {
        border-radius: 0.25rem;
        border: 1px solid black;
        background-color: black;
        color: white;
        width: 10rem;
        cursor: pointer;
    }

    .store_actions_holder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 2.25rem;
        width: 30%;
    }
}

.checkbox {
    position: relative;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
}

.checkbox input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    background-color: white;
    border: 1px solid #4c5763;
    border-radius: 0.125rem;
    cursor: pointer;
    position: relative;
}

.checkbox input[type='checkbox']:hover {
    border: 1px solid #da291c;
}

.checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    left: 35%;
    top: 0.125rem;
    width: 0.375rem;
    height: 0.625rem;
    border: solid #4c5763;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}

.store_editing {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 350ms;
}

.store_editing.active {
    right: 0;
    transition: 350ms;
}

.selected_row {
    background-color: #f3f4f5;
}
