.red_sticker {
    background-color: red;
    color: white;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gotham-Medium';
}

.offer_text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    line-height: 1em;
    max-height: 3em;
    text-align: center;
    font-size: smaller;
}

.offer_long_text {
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: block;
    line-height: 1em;
    max-height: 3em;
    text-align: center;
    font-size: smaller;
}
