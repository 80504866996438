@import '../../../../App.scss';

.card_container {
    background-color: #ffffff;
}

.bold_label {
    font-family: Gotham-Bold;
    font-size: 0.9rem;
    color: #000;
    margin-bottom: 0.125rem;
}

.language_section {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 0.2rem;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
    }
}

.details_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.4rem;
    width: 100%;
}

.image_uploaders {
    display: flex;
    gap: 5rem;
    align-items: center;
    justify-content: flex-start;

    > div {
        flex: 0 0 auto;
        width: 7.5rem;
        min-width: 7.5rem;
        max-width: 7.5rem;
    }

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        gap: 1rem;
    }
}
