.section {
    margin-top: 1.25rem;
    padding: 0.9375rem;
    background-color: #fafafa;
    border-radius: 0.5rem;
}

.sectionTitle {
    font-size: 1rem;
    font-weight: bold;
    color: black;
    margin-bottom: 0.625rem;
    font-family: 'Gotham-Bold';
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Gotham-Book';
    font-size: 0.8125rem;
    line-height: 1.1875rem;

    th,
    td {
        border: 1px solid #e6e6e6;
        padding: 0.5rem;
        text-align: left;
    }

    th {
        background-color: #fafafa;
        font-weight: bold;
        color: black;
    }

    td {
        color: #56616d;
        overflow-wrap: break-word;
    }
}
