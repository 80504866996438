@import '../../App.scss';

.dual_lists {
    display: flex;
    padding-left: 15px;
    @media (max-width: $small-screen-breakpoint) {
        flex-direction: column;
    }
}

.left_content {
    flex: 1;
    margin: 20px 0;
}

.right_content {
    flex: 1;
    margin: 20px 0;
}

.list {
    display: flex;
    flex-direction: column;
}

.coupon_list_header {
    width: 50%;
    display: inline-block;
    margin-bottom: 20px;
    @media (max-width: $small-screen-breakpoint) {
        margin-top: 60px;
    }
}

.save_button_container {
    width: 50%;
    text-align: right;
    display: inline-block;
    padding-right: 40px;
}

.promotion_item {
    padding: 12px 0 12px 0;
    width: 95%;
    height: 230px;
    margin: 8px 0;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-width: 2px;

    &.winner {
        border-style: solid;
        border-color: rgb(218, 183, 31);
    }

    &.active {
        border-style: solid;
        border-color: rgb(28, 108, 169);
    }

    &.expired {
        border-style: solid;
        border-color: rgb(74, 74, 74);
    }

    &.upcoming {
        border-style: solid;
        border-color: rgb(238, 19, 19);
    }

    &.none {
        opacity: 0.3;
    }
}

.promo {
    width: 80px;
    height: 80px;
    background-color: $couchetard-red;
    border-radius: 50%;
    color: #ffffff;
    font-family: Gotham-Medium;
    font-size: 20px;
    position: absolute;
    top: -15%;
    left: -20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :nth-child(1) {
        font-size: 14px;
    }

    &.expired {
        background-color: black;
        opacity: 0.6;
    }
}

.promo span {
    color: #ffffff;
}

.first_row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.left_column {
    width: max-content;
    height: 100%;
}

.right_column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.image {
    position: relative;
    margin-left: 50px;
    margin-top: 30px;
    margin-bottom: 8px;
}

.titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: black;
    margin-top: 20px;

    &.expired {
        color: rgb(74, 74, 74);
    }
}

.description {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.expired {
        color: rgb(130, 130, 130);
    }
}

.date_status {
    color: $greyish-brown;
    text-transform: capitalize;
    margin-top: 25px;

    &.expired {
        color: rgb(182, 182, 182);
    }
}

.description span {
    font-family: Gotham-Book;
}

.right_column img {
    align-self: flex-start;
}

.titles span {
    font-family: Gotham-Medium;
    font-size: 15px;
}

.category {
    width: auto;
    height: auto;
    display: flex;
    padding-right: 10px;
    margin-right: 1px;
    margin-top: 6px;
    flex-direction: column;
    margin-left: auto;
    background-color: $bg-blue;
    border: 1px solid $ice-blue;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.category_text {
    margin-left: 10px;
    color: rgb(19, 102, 166);
    font-family: Gotham-Book;

    &.expired {
        color: rgb(162, 162, 162);
    }
}

.winning {
    width: 160px;
    height: 100%;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    line-height: 1.3;
}

.winning span {
    color: rgb(218, 183, 31);
}

.winning_label {
    display: flex;
    flex-direction: column;

    :nth-child(2) {
        margin-left: 24px;
    }
}

.stars_box {
    width: 40px;
    height: 100%;
}
