@import '../../../../App.scss';

.schedule_container {
    width: 100%;
    background-color: #ffffff;
    padding: 0.1rem;
    box-shadow: none;
}

.schedule_row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.date_picker_column {
    display: flex;
    flex-direction: column;

    .ant-picker {
        border-radius: 0.5rem;
        width: 100%;
        padding: 0.5rem;
        font-size: 1rem;
    }
}

.config_label {
    display: flex;
    align-items: center;
    font-family: Gotham-Bold;
    font-size: 0.9rem;
    color: #000;
    margin-bottom: 0.5rem;
}
