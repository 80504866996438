.container {
    display: flex;
    align-items: center;
    padding: 0.8125rem 0;
    font-family: 'Gotham-Book';
    font-size: 0.8125rem;
    line-height: 1.1875rem;

    .title {
        font-weight: bold;
        color: black;
        min-width: 40%;
    }
}

.rightPadding {
    padding-right: 0.625rem;
}

.searchContainer {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.input {
    flex: 1;
}

.button {
    padding: 0.3125rem 0.5rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.25rem;
    color: white;
    font-size: 0.8125rem;
    line-height: 0.8125rem;
    background-color: #da291c;
    &:hover {
        cursor: pointer;
        background-color: #b71c13;
    }
}
