.time_picker {
    width: 5rem;
    height: 2.5rem;
}

.time_range {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 12rem;
}

.select_hours {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 1rem 0;
}

.open_toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 6.25rem;
}

:global .ant-picker-focused {
    border-color: #da291c;
    box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2);
}

:global .ant-picker {
    border-radius: 6px;
}

:global .ant-picker:hover {
    border-color: #da291c !important;
}

:global .ant-switch-checked {
    background-color: #da291c;
}
