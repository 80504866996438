@import '../../App.scss';

.compare {
    margin-top: 5px;
}

.empty_chart {
    margin: 60px;
}

.export_button {
    float: right;
    margin: 10px;
}

.filters {
    float: left;
    margin-right: 30px;
}

.input_row {
    display: flex;
    padding: 4px;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.line {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 100%;
}

.pagination_container {
    display: flex;
    justify-content: flex-end;
}

.select_filter {
    @extend .filters;
    margin-top: 15px;
}

.stats {
    display: inline-block;
    margin-left: 15px;
}
