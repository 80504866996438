.container {
    padding: 8px;
    position: relative;
    height: 195px;
    width: 160px;
    min-width: 155px;
}

.card {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
}

.card:hover {
    cursor: pointer;
}

.image {
    width: 100%;
    height: 70%;
    display: flex;

    img {
        width: 100%;
    }
}

.title {
    font-family: 'Gotham-Medium';
    color: black;
    padding: 7px;
    line-height: 1em;
    height: 30%;
}
