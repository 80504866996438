.onboardingModal {
    .ant-modal-body {
        padding: 24px;
    }

    .ant-modal {
        border-radius: 12px;
        max-width: 800px;
    }

    .progressionTitle {
        margin-bottom: 12px;
        font-weight: 600;
    }

    .onboardingTable {
        margin-top: 8px;
        border-radius: 8px;

        .ant-table-thead > tr > th {
            background: #f3f3f3;
            font-weight: 600;
            text-transform: uppercase;
        }

        .ant-table-tbody > tr:nth-child(odd) {
            background: #fafafa;
        }

        .ant-table-tbody > tr:hover {
            background-color: #f0f0f0;
        }

        .ant-table-tbody > tr > td {
            padding: 10px;
            border-bottom: 1px solid #e6e6e6;
        }
    }

    .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
    }
}
