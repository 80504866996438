@import '../../../App.scss';

.input_row {
    display: flex;
    padding: 12px 4px;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.image_field {
    margin: 0 90px;
}
