.add_button {
    width: 6.25rem;
    border: none;
    border-radius: 4px;
    color: #344054;
    background-color: #f2f4f7;
    &:hover {
        cursor: pointer;
    }
}
