@import '../../App.scss';

.container {
    background-color: white;
    height: 100vh;
    border: 1px solid #eaecf0;
    width: 563px;
    -webkit-box-shadow: -5px 0px 30px 10px rgba(0, 0, 0, 0.075);
    box-shadow: -5px 0px 30px 10px rgba(0, 0, 0, 0.075);
    overflow: auto;
}

.content {
    margin: 27px 27px 24px 32px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: black;
    height: 8rem;

    .title {
        font-family: 'Gotham-Black';
        font-size: 24px;
        line-height: 24px;
        display: flex;
        margin: 3rem 1.5rem 2rem 0;
        width: 50%;
    }

    .close {
        position: absolute;
        right: 0;
        top: 0;
        &:hover {
            cursor: pointer;
            svg path {
                stroke: $couchetard-red;
            }
        }
    }
}

.store_info {
    display: flex;
    flex-direction: column;
    color: #4c5763;
    b {
        font-size: 16px;
    }
}

.single_info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 2rem;
    margin-bottom: 1rem;
}

.modified_stores {
    display: flex;
    flex-direction: column;
    min-height: 220px;
    overflow-y: auto;
    border-top: 1px solid #e6e6e6;
}

.store {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e6e6e6;
    color: #4c5763;
    min-height: 48px;
    align-items: center;
}

.store_nref {
    margin: 0 1.5rem 0 1.5rem;
}

.save_buttons {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 2rem 0 2rem 0;
}

.reset_button {
    width: 10rem;
    height: 2.25rem;
    border-radius: 4px;
    color: #344054;
    background-color: #f3f4f5;
    border: 1px solid #e6e6e6;
    &:hover {
        cursor: pointer;
    }
}

.save_button {
    width: 10rem;
    height: 2.25rem;
    border-radius: 4px;
    color: white;
    background-color: #da291c;
    border: none;
    &:hover {
        cursor: pointer;
    }
}

.open_hours {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
}
