.promotions_content {
    width: 100%;
    position: relative;
    -webkit-overflow-scrolling: touch;

    :global .ant-tabs-tab-active {
        font-family: 'Gotham-Medium' !important;
        font-weight: bold;
        color: red !important;
    }

    :global .ant-tabs-tab:hover {
        color: red !important;
    }

    :global .ant-tabs-ink-bar {
        background-color: red !important;
        width: 50% !important;
    }

    :global .ant-tabs-bar {
        margin: 0 0 0 0 !important;
        border-bottom: none !important;
    }

    :global .ant-tabs {
        &-nav {
            display: flex;

            .ant-tabs-tab {
                flex-grow: 1;
                margin-right: 0px;
                width: 168.5px;
                text-align: center;
                font-family: 'Gotham-Book';
                font-size: medium;
            }
        }
    }
}

.view_all {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    text-decoration: underline;
    padding-right: 5px;
    font-size: smaller;

    span:hover {
        cursor: pointer;
    }
}

.side_scroll {
    height: 167px;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: hidden;
}

.side_scroll:hover {
    overflow-x: auto;
}

.counter_coupon {
    font-family: 'Gotham-Book';
    position: absolute;
    top: 17px;
    left: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.counter_instore {
    font-family: 'Gotham-Book';
    position: absolute;
    top: 17px;
    left: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}
