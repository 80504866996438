@import '../../App.scss';

.input_row {
    display: flex;
    padding: 12px 4px;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.fixed_width_input_col {
    width: 300px;
    margin-right: 16px;
    @media (max-width: $mobile-breakpoint) {
        width: 100%;
    }
}
