@import '../../App.scss';

.home_container {
    :global .ant-layout {
        min-height: 100vh;
    }

    :global .ant-menu:not(.ant-menu-sub) {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
    }

    // Ant Design overrule
    :global .ant-menu-root > .ant-menu-item:last-child,
    :global .ant-menu-root > .ant-menu-submenu:last-child {
        border-top: 1px solid #e6e6e6;
        padding-left: 10px !important;
        height: 40px;
        width: 240px;
        display: flex;
        align-items: center;
        color: black;
        border-radius: 0px;
        margin: 0;

        span {
            margin-left: 10px;
            height: 39px;
        }
    }

    :global .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title,
    :global .ant-menu > .ant-menu-item {
        padding-left: 10px !important;
        height: 32px;
        margin: 4px 5px 0px 5px;
        width: 230px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        color: black;

        span {
            margin-left: 10px;
            height: 37px;
        }

        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
            margin-left: 10px;
        }
    }

    :global .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title:hover,
    :global .ant-menu:not(.ant-menu-sub) > .ant-menu-item:hover {
        background-color: whitesmoke;

        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
            background: $couchetard-red !important;
        }
    }

    :global .ant-menu-sub .ant-menu-item {
        padding-left: 20px !important;
        font-size: 11px !important;
        height: 30px !important;
        line-height: 30px !important;
        width: 230px;
        margin: 4px 5px 0px 5px;
        border-radius: 6px;
        color: black;
    }

    :global .ant-menu-item {
        span {
            margin-left: 10px;
        }
    }

    :global .ant-menu-sub .ant-menu-item:hover {
        background-color: whitesmoke;
    }

    :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgba(218, 41, 28, 0.1);
        color: $couchetard-red;

        svg path {
            stroke: $couchetard-red;
        }
    }

    :global .ant-menu-submenu-selected {
        span {
            color: $couchetard-red !important;
        }

        svg path {
            stroke: $couchetard-red;
        }

        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
            background: $couchetard-red !important;
        }
    }

    :global .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
        background-color: rgba(218, 41, 28, 0.1);
    }

    :global .ant-menu-inline .ant-menu-item::after {
        border-right: none;
    }

    :global .ant-layout {
        background: white;
    }
    :global .ant-layout-sider-children {
        border-right: 1px solid #e6e6e6;
    }

    :global .ant-menu-inline {
        border-right: none;
    }
}

.logo {
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Gotham-Black';
    color: black;
    font-size: 13px;
    line-height: 13px;
    border-bottom: 1px solid #e6e6e6;
    text-transform: uppercase;

    img {
        height: 20px;
        margin-right: 5px;
        margin-bottom: 2px;
    }
}

.header {
    color: white;
    position: fixed;
    z-index: 1;
    width: 100%;
    display: none;
}

.content {
    padding: 16px 16px 0;
    overflow: hidden;

    .content_box {
        padding: 0;
        min-height: 360;
    }
}

.sider {
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 100;
    font-family: Gotham-Medium;
    font-size: 13px;
    background: white;
    @media (max-width: $tablet-breakpoint) {
        overflow: visible;
    }

    ul {
        & > li,
        & > li > div {
            font-size: 13px !important;
        }
    }
}

.layout {
    margin-left: 240px;
    @media (max-width: $tablet-breakpoint) {
        margin-left: 0;
    }
    font-family: Gotham-Medium;
}

.cashier_layout {
    margin-left: 35px;
    font-family: Gotham-Medium;
    border-left: 1px solid #e6e6e6;
}
