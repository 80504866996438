@import '../../App.scss';

$border-radius-small: 0.25rem;
$border-radius-medium: 0.5rem;
$color-border: #e8e8e8;
$color-background-hover: #fafafa;
$color-background-header: #f0f0f0;
$color-text-dark: #333;
$spacing-small: 0.1rem;
$spacing-medium: 0.75rem;
$spacing-large: 1rem;

.cms_user_history_container {
    padding: $spacing-small;
    border-radius: $border-radius-medium;
}

.version_info {
    font-size: 0.75rem;
    margin-bottom: $spacing-large;
    cursor: pointer;
}

.custom_modal {
    .ant-modal-content {
        border-radius: $border-radius-medium;
        overflow: hidden;
    }
}

.modal_title {
    text-align: center;
    font-size: 1.125rem;
    font-weight: bold;
    color: $color-text-dark;
}

.modal_content {
    padding: $spacing-large;
}

.custom_table {
    .ant-table {
        border: 1px solid $color-border;
        border-radius: $border-radius-small;
    }

    .ant-table-thead > tr > th {
        background-color: $color-background-header;
        font-weight: bold;
        padding: $spacing-medium;
    }

    .ant-table-tbody > tr > td {
        padding: $spacing-medium;
    }

    .ant-table-tbody > tr:hover {
        background-color: $color-background-hover;
    }
}
