.page_container {
    width: 100%;
}

.selector_container {
    display: flex;
    height: 40px;
    width: 100%;
}

.coupons_content {
    background-color: #f7f7f6;
    width: 100%;
    height: 456px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.coupons_content::-webkit-scrollbar {
    display: none;
}

.header {
    padding: 10px;
    margin: 0px;
    font-family: 'Gotham-Bold';
}
