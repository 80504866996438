@import '../../App.scss';

.query_box {
    max-width: 400px;
    margin: 20px 0;
}

.associated_promotions {
    height: 100%;
    position: relative;
    width: 100%;
    overflow: scroll;
    height: 78px;
    padding: 4px 0;

    .container {
        display: flex;
        flex-direction: row;
        position: absolute;
    }
}

.promo_container {
    height: 70px;
    width: 70px;
    margin: 0 12px;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }
}

.promo_img {
    width: 100%;
}
// .title_container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     align-content: center;
//     background-color: rgb(235, 247, 252);
//     border: 1px solid rgb(192, 230, 245);
//     border-radius: 25px;
//     color: rgb(3, 121, 168);

//     p {
//         margin: 0 auto;
//         font-family: Gotham-Book;
//     }
// }
