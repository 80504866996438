.banner {
    position: relative;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 48px;
}

.header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-size: 25px;
    font-family: 'Gotham-Black';
}
