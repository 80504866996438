@import '../../App.scss';

.input_row {
    display: flex;
    padding: 12px 4px;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.fluid_input_col {
    margin-right: 16px;
}

.vertical_col {
    display: flex;
    flex-direction: column;
}

.divider {
    &::before,
    &::after {
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
}

.fixed_width_input_col {
    width: 300px;
    margin-right: 16px;
    @media (max-width: $mobile-breakpoint) {
        width: 100%;
    }
}

.config_container {
    width: 100%;
    display: flex;
}

.items_defs_box {
    width: min-content;
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 40px;
    box-shadow: 0 -15px 15px -15px rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 20px;
    margin-top: 20px;
}

.tag {
    margin-top: 10px;
    span {
        padding: 2px;
        margin: 5px;
    }
}

.header_dates {
    font-size: 14px;
}

.promotions_row_select {
    width: 250px;
}

.custom_option {
    display: flex;
    align-items: center;
}

.titles_holder {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 186px;
    box-sizing: border-box;
    padding-right: 6px;

    & > span {
        text-overflow: ellipsis !important;
        display: block;
        box-sizing: border-box;
        overflow: hidden;
    }
}

.card_container {
    margin: 10px;
    width: min-content;
}

.tooltip {
    padding-right: 5px;
}

.page {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1530px) {
    .page {
        flex-direction: column;
    }
}
