.icon {
    height: 80px;
    width: 80px;
}

.modal_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal {
    :global .ant-modal-confirm-body-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    :global .ant-modal-content {
        border-radius: 8px;
        width: 30rem;
        height: 25rem;
    }

    :global .ant-modal-close {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
    }

    :global .ant-modal-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.5rem;
    }
}

.modal_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    padding: 1rem 0 2rem 0;
    color: black;
}

.title {
    font-family: 'Gotham-Black';
    font-size: 30px;
    line-height: 38px;
    display: flex;
    margin-top: 1rem;
}

.text {
    text-align: center;
    width: 80%;
    font-family: 'Gotham-medium';
}

.buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.red_button {
    width: 10rem;
    height: 2.25rem;
    border-radius: 8px;
    color: white;
    background-color: #da291c;
    border: none;
    &:hover {
        cursor: pointer;
    }
}

.gray_button {
    width: 10rem;
    height: 2.25rem;
    border-radius: 8px;
    color: black;
    background-color: #f3f4f5;
    border: 1px solid #e6e6e6;
    &:hover {
        cursor: pointer;
    }
}
