@import '../../App.scss';

.page_content {
    margin: -16px -16px 0;
    :global .ant-avatar {
        border-radius: 50% !important;
    }

    :global .ant-table-container {
        border-radius: 0px;
    }

    :global .ant-table.ant-table-bordered .ant-table-container {
        border-color: #e6e6e6;
        border-radius: 0px;
        border-left: none;
        border-right: none;
    }

    :global .ant-table-thead .ant-table-cell {
        background-color: #f3f4f5;
        border-color: #e6e6e6;
        color: #6a7581;
    }

    :global .ant-table.ant-table-bordered tbody > tr > td {
        border-right: 1px solid #e6e6e6;
        color: #1d2939;
        font-family: 'Gotham-Book';
        padding: 10px 10px;
    }
}

.input_row {
    display: flex;
    padding: 2px 0px;
    align-items: center;
    justify-content: center;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.name {
    margin-left: 12px;
}

.table_header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 16px 16px 16px 16px;
    position: relative;
}
@media screen and (max-width: 1268px) {
    .table_header {
        flex-direction: column;
        height: 100px;
    }
}

.pagination_container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 75%;
    min-width: 460px;
}

.query_box {
    width: 400px;

    :global .ant-select-selector {
        min-width: 110px;
        height: 32px;
        border-radius: 6px !important;
        border: none !important;
        font-family: 'Gotham-Book';
        color: #1d2939;
        background-color: #f3f4f5 !important;
    }

    :global .ant-select-selection-item {
        line-height: 32px !important;
    }

    :global
        .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }
}

.config_field {
    width: 250px;
}

.no_game_selected {
    font-family: 'Gotham-Black';
    color: black;
    font-size: 34px;
    line-height: 34px;
    display: flex;
    align-items: center;
    height: 200px;
    width: 100%;
    justify-content: center;
}

.custom_option {
    display: flex;
    align-items: center;
}

.titles_holder {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 186px;
    box-sizing: border-box;
    padding-right: 6px;

    & > span {
        text-overflow: ellipsis !important;
        display: block;
        box-sizing: border-box;
        overflow: hidden;
    }
}
