.container {
    width: 150px;
    height: 150px;
    margin-right: 12px;
    position: relative;
}

.image_holder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 2px;
    position: relative;

    img {
        width: 96%;
        margin: 3%;
        user-select: none;
    }

    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        position: absolute;
        transition: opacity 0.4s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover .overlay {
        opacity: 1;
    }
}

.eye_svg {
    svg {
        fill: white;
    }
}

.delete_svg {
    margin-left: 16px;
    display: inline-block;
    svg {
        fill: tomato;
    }
}

.empty_interface {
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    transition: border-color 0.25s;

    &:hover {
        border-color: rgb(24, 144, 255);
        cursor: pointer;
    }
}

.hidden_input {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
    z-index: -100;
    user-select: none;
}
