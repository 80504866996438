.game_content {
    background-color: white;
    width: 100%;
    height: 547px; //541px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 12px;
}

.game_content::-webkit-scrollbar {
    display: none;
}

.back_arrow {
    position: absolute;
    left: 15px;
    height: 20px;
    width: 20px;
}

.back_arrow:hover {
    cursor: pointer;
}

.title {
    color: black;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Gotham-Black';
    line-height: 0.85em;
    text-align: center;

    #subtitle {
        font-family: 'Gotham-Book';
        font-size: small;
        font-weight: lighter;
        color: grey;
        height: 20px;
        padding-top: 5px;
    }
}

.game_banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 337px;
    padding-top: 12px;
    padding-bottom: 12px;

    img {
        height: 100%;
        width: 100%;
    }
}

.info_grid {
    position: relative;
    display: grid;
    font-family: 'Gotham-Book';
    // padding-left: 12px;
    // padding-right: 12px;
    color: black;

    .play_button_container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    #sport_message {
        font-family: 'Gotham-Medium';
        padding: 3px;
        text-align: center;
        line-height: 1em;
    }

    #play_button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #ed8a00;
        height: 40px;
        width: 100%;
        border-radius: 40px;
        cursor: pointer;
        border: none;
        font-family: 'Gotham-Medium';
        font-weight: bold;
        font-size: medium;
        line-height: 1em;
    }

    #play_button:disabled {
        opacity: 0.5;
    }

    .checkboxes {
        color: black;
        font-size: 9px;
        line-height: 9px;
        padding-top: 15px;

        .square_checkbox {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .square_checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            border: solid black 2px;
            border-radius: 3px;
            background-color: white;
        }

        .square_checkbox:hover input ~ .checkmark {
            background-color: #ccc;
        }

        .square_checkbox input:checked ~ .checkmark {
            background-color: black;
        }

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        .square_checkbox input:checked ~ .checkmark:after {
            display: block;
        }

        .square_checkbox .checkmark:after {
            left: 3px;
            top: -2px;
            width: 8px;
            height: 13px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    #description {
        font-size: smaller;
        text-align: center;
        line-height: 0.9em;
        padding-top: 15px;
    }
}
