.scroll_helper {
    position: absolute;
    right: 4px;
    top: 16px;
    width: 10px;
    height: calc(100vh - 32px);
}

.scroll_marker {
    width: 100%;
    position: absolute;
    background-color: tomato;
}
