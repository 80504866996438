.page_container {
    width: 100%;
}

.selector_container {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: center;
}

.instore_content {
    background-color: #f7f7f6;
    width: 100%;
    height: 456px;
    justify-content: center;
    justify-items: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.instore_content::-webkit-scrollbar {
    display: none;
}

.header {
    padding: 10px;
    margin: 0px;
}
