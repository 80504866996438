.carousel {
    overflow: hidden;
    position: relative;
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
    height: 280px;
}

.carousel_item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    img {
        width: 100%;
    }
}

.indicators {
    display: inline-flex;
    justify-content: center;
    position: absolute;
    top: 230px;
    width: 100%;

    .active {
        background-color: white;
        margin: 8px;
        height: 8px;
        width: 8px;
        border: none;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.24);
    }

    .inactive {
        background-color: rgba(240, 239, 239, 0.2);
        margin: 8px;
        height: 8px;
        width: 8px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.24);
    }

    .inactive:hover {
        background-color: rgba(240, 239, 239, 0.6);
    }
}
