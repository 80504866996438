.home_content {
    height: 628px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.scroll_content {
    height: 549px;
    width: 100%;
    overflow: auto;
    background-color: white;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll_content::-webkit-scrollbar {
    display: none;
}

.CT_logo {
    position: absolute;
    left: 7px;
    top: 40px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: white;
}

.text {
    font-size: 15px;
    position: absolute;
    left: 50px;
}

.refresh_arrow {
    position: absolute;
    right: 10px;
    top: 45px;
    height: 25px;
    width: 25px;
}

.title {
    font-family: 'Gotham-Medium';
    color: black;
    padding-left: 10px;
}
