@import '../../App.scss';

.page_content {
    margin: -16px -16px 0;
}

.site_page_header {
    padding: 0;
    font-family: Gotham-Bold;
    font-size: 1.5em;
}

.card_dashboard {
    margin-top: 20px;
}

.dashboard_title {
    margin-right: 20px;
}

.stats {
    display: inline-block;
    width: 200px;
}
.card_container {
    margin: 10px;
}

.input_row {
    display: flex;
    padding: 2px 4px;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.section {
    padding-top: 20px;
}

.fluid_input_col {
    margin-right: 16px;
}

.list {
    width: 600px;
}

.date_picker_header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 16px 16px 16px 16px;
}

.layout_section {
    padding: 20px 20px;
    background-color: #f3f4f5;
}

.download_csv {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    border-bottom: 1px solid grey;
    margin-left: 25px;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
