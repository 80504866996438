@import '../../../../App.scss';

.input_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 1.2rem;
    padding: 0.1rem;
}

.single_input {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    flex: 1;
}

.label_with_tooltip {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.bold_label {
    font-family: Gotham-Bold;
    font-size: 0.9rem;
    color: #000;
}
