@import '../../App.scss';

.coupons_modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;

    .coupons_modal_content {
        background-color: white;
        width: 580px;
        height: 640px;
        position: relative;
        overflow: auto;
    }

    .header {
        margin: 27px 27px 20px 32px;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        position: relative;
        color: black;
        justify-content: center;

        .close {
            position: absolute;
            right: 0;
            top: 0;
            &:hover {
                cursor: pointer;
                svg path {
                    stroke: $couchetard-red;
                }
            }
        }
    }
}
