.promotions_content {
    width: 100%;
    height: 628px; //590px;
    position: relative;
    overflow: hidden;

    :global .ant-tabs-tab-active {
        color: black !important;
        font-weight: 500 !important;
    }

    :global .ant-tabs-tab:hover {
        color: black !important;
    }

    :global .ant-tabs-ink-bar {
        background-color: red !important;
        width: 50% !important;
    }

    :global .ant-tabs-bar {
        margin: 0 0 8px 0 !important;
        border-bottom: none !important;
    }

    :global .ant-tabs {
        &-nav {
            display: flex;

            .ant-tabs-tab {
                flex-grow: 1;
                margin-right: 0px;
                width: 168px;
                text-align: center;
                font-family: 'Gotham-Medium';
            }
        }
    }

    :global .ant-select {
        height: 25px !important;
        display: flex;
        align-items: center;
        color: black !important;
        font-family: 'Gotham-Book';
    }

    :global .ant-select-arrow {
        color: black !important;
    }

    :global .ant-select-selector {
        display: flex;
        align-items: center !important;
        background-color: #f7f7f6 !important;
        border: none !important;
        border-radius: 5px !important;
        height: 25px !important;
        flex-grow: 1;
    }
}
