@import '../../App.scss';

.input_row {
    display: flex;
    padding: 0.75rem 0.25rem;
    flex-direction: row;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }

    & > div {
        margin-right: 1.25rem;
        width: 18.75rem;
    }
}

.input_row_responsive {
    @extend .input_row;
    flex-wrap: wrap;
    padding: 0.75rem 0.25rem;
    row-gap: 1.25rem;
}

.input_row_rewards {
    @extend .input_row;
    & > div {
        margin-right: 1.25rem;
        width: 100%;
    }
}

.card_container {
    margin: 0.625rem;
}

.site_page_header {
    padding: 0;
    font-family: Gotham-Bold;
    font-size: 1.5em;
}

.tooltip,
.is_featured {
    padding-right: 0.313rem;
}

.stats {
    display: inline-block;
    width: 6.25rem;
}

.config_field {
    width: 12.5rem;
}
