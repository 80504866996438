.image_holder {
    overflow: hidden;
}

.image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;

    &.expired {
        filter: grayscale(100%);
    }
}
