@import '../../App.scss';

$background-color: #f2f1f2;
$font-family-bold: Gotham-Bold;
$primary-color: #2f2f2f;
$white-color: white;
$box-shadow: 0 -0.9375rem 0.9375rem -0.9375rem rgba(0, 0, 0, 0.2);
$border-color: rgba(0, 0, 0, 0.2);
$spacing-small: 0.75rem;
$spacing-medium: 1.25rem;
$spacing-large: 1.1rem;

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

.container {
    background-color: $background-color;
    margin: -1rem;
}

.section_header_base {
    width: 100%;
    margin: 0;
    font-family: $font-family-bold;
    font-size: 1.1rem;
    color: $primary-color;
}

.section_header {
    @extend .section_header_base;
    padding-top: $spacing-large;
}

.section_header_config {
    @extend .section_header_base;
}

.left_side {
    @include flex-row;
    flex-wrap: wrap;
    padding: $spacing-small 0.25rem;
    height: 100%;
    overflow: auto;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.config_viewer_split {
    @include flex-row;
    align-items: flex-start;
    overflow: auto;

    @media screen and (max-width: 81.25rem) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.preview_container {
    margin-top: $spacing-medium;
    margin-left: $spacing-medium;
    position: sticky;
    height: fit-content;
}

.page_title_container {
    background-color: $white-color;
    margin: 0;
    border-bottom: 1px solid #e6e6e6;
    padding: 0.3rem;
}

.full_width_row {
    width: 100%;
    @include flex-column;
}

.divider {
    &::before,
    &::after {
        border-top: 1px solid $border-color !important;
    }
}
