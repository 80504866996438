@import '../../App.scss';

.pagination {
    :global .ant-pagination.mini {
        display: flex;
        align-items: center;
        color: #787878;
        font-family: 'Gotham-Medium';
        font-size: 13px;

        .ant-pagination-options {
            .ant-select-selector {
                height: 32px;
                width: 186px;
                padding: 6px 10px 6px 12px;
                justify-content: space-between;
                border-radius: 6px;
                border: none;
                background-color: #f3f4f5;
                font-family: 'Gotham-Book';
                color: #1d2939;
            }

            .ant-select-arrow {
                color: #6a7581;
            }
        }
    }

    :global .ant-pagination-item-active {
        border-color: $couchetard-red !important;
        a {
            color: $couchetard-red !important;
        }
    }

    :global .ant-pagination-item a:hover {
        color: $couchetard-red;
    }

    :global
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: rgba(218, 41, 28, 0.1);
    }

    :global
        .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: $couchetard-red;
    }

    :global
        .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: $couchetard-red;
    }

    :global .ant-pagination-next {
        a:hover {
            color: $couchetard-red;
        }
    }

    :global .ant-pagination-prev {
        a:hover {
            color: $couchetard-red;
        }
    }

    :global
        .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }
}
