.card_content {
    padding-top: 20px;

    & > div {
        margin-bottom: 12px;
    }
}

.card {
    margin-bottom: 30px;
}
