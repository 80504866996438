@import '../../App.scss';

.filters {
    float: left !important;
    margin-right: 30px !important;
    margin-top: 15px !important;
}

.select_filter {
    @extend .filters;
    margin-top: 15px !important;
}
