@import '../../App.scss';

.container {
    margin: -16px;
}

.page_title_container {
    background-color: white;
    margin: 0;
    padding: 0.3rem;
}

.card_container {
    margin-top: 0.2rem;
    width: 100%;
}

.input_row {
    margin: 0.6rem;
}

.input_class {
    margin-left: 0.3rem;
}
