.cards_layout {
    width: 100%;
    height: 520px;
    overflow: hidden;
    position: relative;
    padding: 15px;
}

.header {
    background-color: silver;
    width: 260px;
    height: 40px;
    margin-left: 38px;
    margin-top: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
}

.card {
    background-color: lightgray;
    width: 145px;
    height: 200px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
}

.first {
    position: absolute;
    // left: 30px;
}

.sec {
    position: absolute;
    left: 180px;
    top: 80px;
}

.third {
    position: absolute;
    top: 230px;
}

.fourth {
    position: absolute;
    left: 180px;
    top: 295px;
}
