.container {
    padding: 10px;
    position: relative;
    height: 140px;
    width: 125px;
    min-width: 125px;
}

.card {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
}

.card:hover {
    cursor: pointer;
}

.image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}

.red_sticker {
    position: absolute;
    top: -15px;
    left: 0;
    margin: 2px;
}
