$iphone-height: 730px;
$iphone-width: 363px;

.iphone {
    position: relative;
    display: inline-block;
    align-content: center;
    justify-content: center;
    height: $iphone-height;
    width: $iphone-width;
    overflow: hidden;
    border-radius: 54px;
}

.iphone .content {
    display: block;
    position: absolute;
    margin-left: 13px;
    height: 719px;
    width: 337px;
    border-radius: 38px;
    overflow: hidden;
}

.iphone_home_bar {
    background-color: white;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
}

.swipe_bar {
    background-color: black;
    height: 5px;
    width: 130px;
    border-radius: 5px;
}
