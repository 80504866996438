.canvas {
    width: 400px;
    height: 810px;
    display: flex;
    justify-content: center;
    border-radius: 40px;
    padding: 10px;
    background-color: white;
    -webkit-box-shadow: 0px 7px 12px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 7px 12px 5px rgba(0, 0, 0, 0.1);
}
