@import '../../App.scss';

.container {
    padding-bottom: 20px;
}

.pagination {
    margin-top: 30px;
}

.clickable_span {
    transition: color 0.25s;
    &:hover {
        color: $ct-blue;
        cursor: pointer;
    }
}
