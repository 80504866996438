@import '../../App.scss';

.input_row {
    display: flex;
    padding: 0.75rem 0.25rem;
    flex-direction: row;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }

    & > div {
        margin-right: 1.25rem;
        width: 18.75rem;
    }
}

.input_row_responsive {
    @extend .input_row;
    flex-wrap: wrap;
    padding: 0.75rem 0.25rem;
}

.button_container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 37.5rem;
}

.tooltip {
    padding-right: 0.313rem;
}
