@import '../../App.scss';

.container {
    margin-bottom: 20px;
}

.input_row {
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }

    & > div {
        margin-right: 20px;
        width: 300px;
    }
}

.section {
    display: flex;
    flex-direction: row;
    max-width: 300px;
    flex: 1;
}

.right_section {
    @extend .section;
    align-items: stretch;
}

.pucks {
    margin-right: 5px;
    align-items: flex-start;
    flex: 5;
    max-width: 200px;
}

.remove {
    flex: 1;
}
