.container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    height: 155px;
}

.card {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%; //320px;
    height: 100%; //135px;
    border-radius: 15px;
    padding: 5px;
    -webkit-box-shadow: 0px 5px 11px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 11px 1px rgba(0, 0, 0, 0.2);
}

.image {
    min-width: 100px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;

    img {
        width: auto;
        max-width: 100px;
        height: 100px;
    }
}

.text_grid {
    position: relative;
    display: grid;
    padding-left: 10px;
    padding-top: 5px;
    height: 125px;
    font-family: 'Gotham-Book';

    #title {
        color: black;
        font-weight: bold;
        font-family: 'Gotham-medium';
    }

    #description {
        font-size: smaller;
        color: black;
        line-height: 1em;
        padding-top: 7px;
    }

    #link {
        color: #ad2d1f;
        font-weight: bold;
        text-decoration: underline;
    }
}
