@import '../../App.scss';

.input_row {
    display: flex;
    padding: 12px 4px;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.fluid_input_col {
    margin-right: 16px;
}

.vertical_col {
    display: flex;
    flex-direction: column;
}
