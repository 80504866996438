@import '../../App.scss';

.rich_text_editor {
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    height: 30vh !important;
    overflow: scroll;
}

.is_disabled {
    opacity: 0.3;
}

:global .inline-link {
    color: $ct-blue;
    text-decoration: underline;
}

.rich_text_editor .editor-container {
    overflow: scroll;
    padding: 20px;
    flex: 1;
    max-width: 100%;
}

.rich_text_editor .toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    width: 100%;
}

.rich_text_editor .toolbar :global ant-btn {
    color: rgba(0, 0, 0, 0.55);
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    transition: all 0.3s;
    outline: none;
}

.remove_icon {
    svg {
        transition: fill 0.3s;
        margin-left: 6px;
    }

    &:hover svg {
        fill: tomato;
        cursor: pointer;
    }
}
