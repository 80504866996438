.content {
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.title {
    font-family: 'Gotham-Medium';
    color: black;
    padding-left: 10px;
}

.featured_card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.side_scroll {
    height: 220px;
    padding-top: 10px;
    padding-left: 3px;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: hidden;
}

.side_scroll:hover {
    overflow-x: auto;
}
