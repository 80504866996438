.placeholder_content {
    width: 100%;
    height: 628px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    font-family: 'Gotham-Black';
    font-size: larger;
    svg path {
        fill: black;
    }
    svg {
        height: 100px;
        width: 100px;
    }
}
