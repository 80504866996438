@import '../../App.scss';

.fluid_input_col {
    margin-right: 16px;
}

.vertical_col {
    display: flex;
    flex-direction: column;
}
