@import '../../App.scss';

.container {
    background-color: white;
    height: 100vh;
    border: 1px solid $couchetard-grey;
    width: 35rem;
    -webkit-box-shadow: -5px 0px 30px 10px rgba(0, 0, 0, 0.075);
    box-shadow: -5px 0px 30px 10px rgba(0, 0, 0, 0.075);
    overflow: auto;
}

.header {
    margin: 27px 27px 24px 32px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: black;

    .clientd_id {
        padding-top: 5px;
        font-family: 'Gotham-Book';
        line-height: 13px;
        font-size: 13px;
    }

    .deleted_user {
        margin-left: 15px;
    }

    .close {
        position: absolute;
        right: 0;
        top: 0;
        &:hover {
            cursor: pointer;
            svg path {
                stroke: $couchetard-red;
            }
        }
    }
    .user_info {
        display: flex;
        align-items: center;
        margin-top: 16px;

        :global .ant-avatar {
            border-radius: 50% !important;
        }

        .user_info_and_contact {
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            .user_title {
                font-family: 'Gotham-Black';
                font-size: 24px;
                line-height: 24px;
                display: flex;

                :global .ant-tag {
                    width: 30px;
                    height: 24px;
                    border: none;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 24px;
                    font-size: 13px;
                    font-family: 'Gotham-Book';
                }
            }

            .user_contact {
                font-family: 'Gotham-Book';
                line-height: 13px;
                font-size: 13px;
                margin-top: 9px;
                display: flex;

                .confirmed {
                    margin-left: 6px;
                    margin-top: 2px;
                }

                .phone_number {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .email {
                    margin-left: 16px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
    }
}

.order {
    margin: 32px;

    .order_status_tag {
        border: none;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
    }

    .order_date {
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
        margin-top: 32px;
        .order_day_date {
            font-family: 'Gotham-Black';
            color: black;
            font-size: larger;
        }

        .order_location {
            color: black;
            font-family: 'Gotham-Book';
        }

        .order_store_id {
            color: black;
            font-family: 'Gotham-Book';
        }
    }

    .item,
    .item_discount {
        padding: 12px 0px;
        border-bottom: 1px solid $couchetard-grey;
        position: relative;
        display: flex;
        justify-content: space-between;
        font-family: 'Gotham-Book';
    }

    .item_discount {
        font-size: small;
        color: $couchetard-red;
    }

    .order_receipt {
        padding: 0px 16px;
    }

    .order_items_container {
        display: flex;
        flex-direction: column;
        margin-top: 32px;

        .order_items_header {
            display: flex;
            justify-content: space-between;
            position: relative;
            padding: 12px 16px;
            background-color: $couchetard-grey;
        }

        .order_items {
            margin-bottom: 3px;
        }

        .order_item_description,
        .order_item_description_discount {
            display: flex;
            max-width: 350px;
        }

        .order_item_description_discount {
            color: $couchetard-red;
        }

        .order_item_quantity,
        .order_item_quantity_discount {
            position: absolute;
            right: 96px;
        }

        .order_item_quantity_discount {
            color: $couchetard-red;
        }

        .order_subtotal_taxes {
            border-bottom: 1px solid $couchetard-grey;
            border-top: 1px solid $couchetard-grey;
            padding: 12px 0;
            margin-bottom: 3px;
            .order_tax {
                display: flex;
                justify-content: space-between;
                font-family: 'Gotham-Book';
            }

            .order_subtotal {
                display: flex;
                justify-content: space-between;
            }
        }

        .order_total {
            display: flex;
            justify-content: space-between;
            font-family: 'Gotham-Black';
            padding: 12px 0;
            border-top: 1px solid $couchetard-grey;
            font-size: larger;
        }
    }

    .order_payment {
        display: flex;
        flex-direction: column;
        margin-top: 32px;

        .order_payment_header {
            padding: 12px 16px;
            background-color: $couchetard-grey;
        }

        .order_payment_infos {
            margin-bottom: 3px;
        }

        .border {
            border-bottom: 1px solid $couchetard-grey;
        }
    }
}
