$small-screen-breakpoint: 1224px;
$tablet-breakpoint: 992px;
$mobile-breakpoint: 500px;

$ice-blue: #e8f0f6;
$bg-blue: rgb(240, 245, 249);
$ct-blue: #1366a6;
$ct-orange: #f6931e;
$greyish-brown: rgb(74, 74, 74);
$couchetard-red: #da291c;
$couchetard-grey: #eaecf0;
$active-green: #24b040;
$inactive-grey: #6a7581;
$pending-orange: darkorange;

:global .ant-pagination {
    text-align: right;
    margin: 20px 0;
}
:global .ant-avatar {
    border-radius: 0 !important;
}

:global .ant-spin-dot-item {
    background-color: $couchetard-red;
}

h1 {
    font-family: Gotham-Bold;
    font-size: 1.5em;
}
h2 {
    font-family: Gotham-Bold;
    font-size: 1.3em;
}
h3 {
    text-transform: uppercase;
    font-family: Gotham-Bold;
    color: #4a4a4a;
}
ul {
    padding-left: 20px;
}
:global .ant-card-meta-title {
    text-transform: uppercase;
    font-family: Gotham-Bold;
    color: #4a4a4a;
    margin-bottom: 20px;
}
:global .ant-card-meta-avatar {
    margin-top: -8px;
}
:global .left_label {
    display: inline-block;
    margin-right: 12px;
}
:global .text_input {
    width: 260px;
    margin-right: 40px;
}
:global .dates_holder {
    white-space: normal;
    font-size: 12px;

    & > div:not(.mid_label) {
        /* margin: 0 5px; */
        padding: 0 7px;
        border-right: 1px solid #f0f0f0;
        margin-bottom: 15px;
    }
    & > div:last-child {
        border: none;
    }
}
:global .left_spacer {
    margin-left: 20px;
}
:global .lil_top_spacer {
    margin-top: 6px;
}
:global .top_spacer {
    margin-top: 20px;
}
:global .clickable_table tr {
    cursor: pointer;
}

$thumbnail-diameter: 70px;
:global {
    .carousel_wrapper {
        width: $thumbnail-diameter;
        background: green;
        overflow: hidden;
        .ant-carousel {
            width: $thumbnail-diameter;
        }
        .ant-carousel .slick-slider {
            text-align: center;
            height: $thumbnail-diameter;
            width: $thumbnail-diameter;
            line-height: $thumbnail-diameter;
            overflow: hidden;
        }
    }
}
:global .thumbnail {
    width: $thumbnail-diameter;
    height: $thumbnail-diameter;
}
:global .acid_blue {
    color: #1890ff;
}
:global .light_label {
    font-family: Gotham-Book;
    color: #909090;
}
:global .big_label {
    font-size: 20px;
}
:global .mid_label {
    font-size: 15px;
}
:global .lil_label {
    font-size: 10px;
}
:global .small_label {
    font-size: 12px;
}

:global .buttons_holder {
    margin: 24px 0 12px 0;

    #create-button.ant-btn-primary {
        background-color: #4caf50;
        border-color: #4caf50;

        &[disabled] {
            opacity: 0.6;
        }
    }

    & > button {
        margin-right: 20px;
    }
}

:global .ant-btn-dangerous.ant-btn-primary {
    background-color: #ff0000 !important;
}

:global .ant-btn-dangerous.ant-btn-primary:hover {
    background-color: #ff4d4f !important;
}

:global .ant-tag {
    border-radius: 4px;
    border: none;
}

@font-face {
    src: url('./fonts/gotham_black.ttf');
    font-family: Gotham-Black;
}

@font-face {
    src: url('./fonts/gotham_bold.ttf');
    font-family: Gotham-Bold;
}

@font-face {
    src: url('./fonts/gotham_book.ttf');
    font-family: Gotham-Book;
}

@font-face {
    src: url('./fonts/gotham_book_italic.ttf');
    font-family: Gotham-Book-Italic;
}

@font-face {
    src: url('./fonts/gotham_medium.ttf');
    font-family: Gotham-Medium;
}

@font-face {
    src: url('./fonts/gotham_ultra.woff');
    font-family: Gotham-Ultra;
}

.section_title {
    font-family: 'Gotham-Black';
    height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 13px;
    font-size: 16px;
    margin-bottom: 0;
}

:global {
    .ant-select-selector,
    .ant-input,
    .ant-input-number,
    .ant-input-number-input {
        border-radius: 6px !important;
    }

    .ant-select-selector:hover,
    .ant-input:hover,
    .ant-input-number:hover,
    .ant-input-number-input:hover {
        border-color: $couchetard-red !important;
    }

    .ant-select-focused .ant-select-selector,
    .ant-input:focus,
    .ant-input-number:focus,
    .ant-input-number-input:focus {
        border-color: $couchetard-red !important;
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }

    .ant-input-number-status-error,
    .ant-input-status-error,
    .ant-select-status-error .ant-select-selector {
        border-color: $couchetard-red !important;
    }

    .ant-input-number-status-error:focus,
    .ant-input-status-error:focus,
    .ant-select-status-error.ant-select-focused .ant-select-selector,
    .ant-input-number.ant-input-number-focused {
        border-color: $couchetard-red !important;
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }

    .ant-select-arrow {
        color: #667085;
    }
}
