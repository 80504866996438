.page_content {
    width: 100%;
    height: 628px;
    position: relative;
    overflow: hidden;
}

.promotion_content {
    width: 100%;
    height: 548px;
    overflow: auto;
    background-color: white;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.promotion_content::-webkit-scrollbar {
    display: none;
}

.back_arrow {
    position: absolute;
    left: 15px;
    height: 20px;
    width: 20px;
}

.back_arrow:hover {
    cursor: pointer;
}

.promo_banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #f7f7f6;
    width: 100%;
    height: 200px;

    img {
        max-width: 100%;
        max-height: 85%;
    }
}

.red_sticker {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
}

.text_grid {
    background-color: white;
    position: relative;
    display: grid;
    font-family: 'Gotham-Book';
    padding: 25px;

    #category {
        color: red;
        font-size: smaller;
        padding-bottom: 5px;
    }

    #title {
        color: black;
        font-weight: bold;
        font-size: medium;
        padding-bottom: 5px;
    }

    #dates {
        font-size: small;
        color: black;
    }

    .coupon_button_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
    }

    #coupon_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        background-color: red;
        height: 30px;
        width: 150px;
        border-radius: 15px;
        cursor: pointer;
        border: none;
        font-family: 'Gotham-Medium';
        #coupon_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 1px solid white;
            margin: 5px;
            svg {
                height: 10px;
                width: 10px;
                transform: rotate(225deg);
            }

            svg path {
                fill: white;
            }
        }
    }

    #description {
        font-size: small;
        color: black;
        padding-top: 5px;
    }
}
