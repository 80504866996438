@import '../../App.scss';

.stats {
    padding-top: 25px !important;
    padding-left: 20px !important;
}

.container {
    background-color: white;
    padding: 20px;
}
