.container {
    padding: 10px;
    position: relative;
    height: 155px;
}

.card {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%; //320px;
    height: 100%; //135px;
    border-radius: 10px;
    padding: 5px;
    -webkit-box-shadow: 0px 5px 11px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 11px 1px rgba(0, 0, 0, 0.2);
}

.card:hover {
    cursor: pointer;
}

.image {
    min-width: 125px;
    width: 125px;
    height: 125px;
    display: flex;
    justify-content: center;

    img {
        width: auto;
        max-width: 125px;
        height: 125px;
    }
}

.text_grid {
    position: relative;
    display: grid;
    padding-left: 10px;
    padding-top: 10px;
    height: 125px;
    font-family: 'Gotham-Book';

    #category {
        color: red;
        font-size: smaller;
    }

    #title {
        color: black;
        font-weight: bold;
        font-size: medium;
    }

    #dates {
        font-size: smaller;
        color: black;
        line-height: 1.25em;
    }

    #coupon_icon_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: black;
        font-size: small;
        font-family: 'Gotham-medium';
        #coupon_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 1px solid black;
            margin: 5px;
            svg {
                height: 12px;
                width: 12px;
                transform: rotate(225deg);
            }

            svg path {
                fill: black;
            }
        }
    }
}

.red_sticker {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2px;
}
