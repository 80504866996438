@import '../../App.scss';

.buttons_holder {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin-top: 0.5rem;
}

.edit_button {
    border-radius: 4px;
    color: #344054;
    background-color: #f3f4f5;
    border: 1px solid #e6e6e6;
    width: 5rem;
    &:hover {
        cursor: pointer;
    }
}

.cancel_button {
    border-radius: 4px;
    color: #344054;
    background-color: #f3f4f5;
    border: 1px solid #e6e6e6;
    &:hover {
        cursor: pointer;
    }
}

.confirm_button {
    border: none;
    border-radius: 4px;
    color: white;
    background-color: $couchetard-red;
    &:hover {
        cursor: pointer;
    }
}

.confirm_button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
