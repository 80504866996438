@import '../../App.scss';

.clients_content {
    margin: -16px -16px 0;
    :global .ant-table-container {
        border-radius: 0px;
    }

    :global .ant-table.ant-table-bordered .ant-table-container {
        border-color: #e6e6e6;
        border-radius: 0px;
        border-left: none;
        border-right: none;
    }

    :global .ant-table-thead .ant-table-cell {
        background-color: #f3f4f5;
        border-color: #e6e6e6;
        color: #6a7581;
    }

    :global .ant-table-thead .ant-table-cell:nth-last-child(-n + 3) {
        border-right: none;
    }

    :global .ant-table.ant-table-bordered tbody > tr > td {
        border-right: 1px solid #e6e6e6;
        color: #1d2939;
        font-family: 'Gotham-Book';
        padding: 10px 10px;
    }

    :global
        .ant-table.ant-table-bordered
        tbody
        > tr
        > td:nth-last-child(-n + 2) {
        border-right: none;
    }

    :global
        .ant-table.ant-table-bordered
        tbody
        > tr
        > td:nth-last-child(-n + 8) {
        color: #4c5763;
    }

    :global .ant-table-tbody > tr > td {
        border-bottom: 1px solid #e6e6e6;
    }

    :global .ant-avatar {
        border-radius: 50% !important;
    }
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    :global .ant-btn {
        border: none;
        display: flex;
        align-items: center;
        box-shadow: none;
        background-color: transparent;
        &::after {
            box-shadow: 0 0 0 5px rgba(218, 41, 28, 0.6) !important;
        }
    }

    .more_options {
        height: 20px;
        width: 20px;
        padding: 0;
        display: flex;
        justify-content: center;
        svg path {
            fill: #4c5763;
        }
        &:hover {
            svg path {
                fill: $couchetard-red;
            }
        }
    }
}

.table_header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 16px 16px 16px 16px;
    position: relative;
}
@media screen and (max-width: 1268px) {
    .table_header {
        flex-direction: column;
        height: 100px;
        margin-bottom: 16px;
    }
}
.query_box {
    width: 400px;
}

.clickable_span {
    display: flex;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
    transition: color 0.25s;
    &:hover {
        color: $couchetard-red;
        cursor: pointer;
    }
}

// Make sure that the popover is displayed UNDER the modal overlay ( which as a 1000 z-index )
.popover_overlay {
    z-index: 999;
}

.divider {
    border: 0.5px solid #e6e6e6;
}

.pagination_container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 75%;
    min-width: 460px;
}

.name {
    margin-left: 12px;
}

.user_profile {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 350ms;
}

.user_profile.active {
    right: 0;
    transition: 350ms;
}

.reissue {
    display: flex;
    justify-content: center;
}

.reissue_button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: none;

    &:hover {
        background-color: #dfe0e1;
        cursor: pointer;
    }

    svg path {
        stroke: #4c5763;
    }
}

.coupons_header {
    .header_description {
        font-family: 'Gotham-Book';
        line-height: 16px;
        font-size: 13px;
    }

    .user_name {
        font-family: 'Gotham-Black';
        font-size: 24px;
        line-height: 29px;
    }
}

.description_style {
    margin-right: 30px;
    margin-left: 30px;
}

.row_style {
    margin: 30px;
}

.pins_style {
    margin-right: 10px;
}

.contact_info {
    display: flex;
    flex-direction: row;

    .confirmed {
        margin-right: 0.2rem;
        margin-top: 0.2rem;
    }
}
