@import '../../App.scss';

.input_row {
    display: flex;
    padding: 4px;
    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.filters {
    float: left !important;
    margin-right: 30px !important;
}

.compare {
    margin-top: 7px !important;
    margin-left: 15px !important;
}

.switch_button {
    margin-left: 10px !important;
}
