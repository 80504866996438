.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.box {
    background-color: #fafafa;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.clientId {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Gotham-Bold;
}

.label {
    font-weight: bold;
}

.modal .ant-btn-primary {
    background-color: #da291c;
    border-color: #da291c;
    font-family: Gotham-Bold;
}
.modal .ant-btn-primary:hover,
.modal .ant-btn-primary:focus {
    background-color: #b71c13;
    border-color: #b71c13;
}

.ant-btn {
    background-color: #da291c;
}
