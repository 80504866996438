@import '../../App.scss';

.page_content {
    margin: -16px -16px 0;
}

.verticalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f1f2;
}

.date_picker_header {
    display: flex;
    align-items: center;
    height: 4em;
    padding: 1em;
}

.container {
    padding: 1.25em;
}

.section_container {
    padding-bottom: 0.9375em;
}

.main_container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    @media (max-width: 48em) {
        flex-direction: column;
    }
}

.box_container {
    display: flex;
    flex-direction: column;
    min-width: 18.75em;
    padding-right: 2em;

    .section_title {
        margin-bottom: 0.3125em;
    }

    .row {
        display: flex;
        gap: 0.9375em;

        @media (max-width: 48em) {
            flex-direction: column;
            align-items: center;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    > div {
        margin-right: 0.625em;

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 767px) {
            width: calc(50% - 0.625em);
            margin: 0.3125em auto;
        }

        @media (max-width: 480px) {
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.date_picker_row {
    padding: 1em 2em;
    background-color: #f2f1f2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 4em;
}

.date_picker {
    .ant-picker {
        border-radius: 8px;
        font-size: 14px;
        padding: 0.5rem;
    }
}

.warning_banner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #fffbe6;
    border: 1px solid #faad14;
    border-radius: 8px;
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    color: #b45309;
}

.stats_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.stats_box {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 220px;
    min-height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
}
