.card {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 155px;
    width: 317px;
    min-width: 155px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.2);
}

.card:hover {
    cursor: pointer;
}

.image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    img {
        min-width: 100%;
        min-height: 100%;
        flex-shrink: 0;
        border-radius: 10px;
    }
}
