@import '../../App.scss';

.visits_content {
    margin: -16px -16px 0;
    :global .ant-table-container {
        border-radius: 0px;
    }

    :global .ant-table.ant-table-bordered .ant-table-container {
        border-color: #e6e6e6;
        border-radius: 0px;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #e6e6e6;
    }

    :global .ant-table-thead .ant-table-cell {
        background-color: #f3f4f5;
        border-color: #e6e6e6;
        color: #6a7581;
    }

    :global .ant-table.ant-table-bordered tbody > tr > td {
        border-right: 1px solid #e6e6e6;
        color: #1d2939;
        font-family: 'Gotham-Book';
        padding: 10px 10px;
    }

    :global .ant-table-body {
        overflow-y: overlay !important;
    }

    :global .ant-picker-focused {
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }

    :global .ant-picker:hover {
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }

    :global .ant-input-affix-wrapper {
        width: 300px;
    }

    :global .ant-picker-active-bar {
        background: $couchetard-red;
    }
}

.table_header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 16px 16px 16px 16px;
    position: relative;
}

.item_quantity {
    display: inline-block;
    border: 2px solid rgb(144, 144, 144);
    border-radius: 50%;
    width: 27px;
    height: 27px;
    line-height: 25px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin-left: 10px;
}

.query_box {
    display: flex;
    flex-direction: row;
}

.pagination_container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 75%;
    min-width: 460px;
}

.client_name {
    font-family: 'Gotham-Black';
}

.stop_outlined {
    text-align: center;
}

.minutes_to_receipt {
    font-family: 'Gotham-Book';
}

.time_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.time_indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 12px;
}

#long_time {
    background: $couchetard-red;
}

#short_time {
    background: #0b8e8e;
}

#no_time {
    background: lightgrey;
}

.range_picker {
    border-radius: 6px;
    background-color: #f3f4f5;
    font-family: 'Gotham-Book';
    border: none;
    width: 350px;
    margin-left: 12px;
}

@media screen and (max-width: 1535px) {
    .table_header {
        flex-direction: column;
        height: 100px;
        margin-bottom: 16px;
    }

    .pagination_container {
        justify-content: center;
    }
}
.xlsx_download_btn {
    margin-right: 16px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    width: 12rem;
}

.visit_details {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 350ms;
}

.visit_details.active {
    right: 0;
    transition: 350ms;
}
