@import '../../../App.scss';

.coupons_tab {
    .active_row {
        color: #4c5763;
    }

    .expired_row {
        color: $inactive-grey;
        pointer-events: none;
        filter: grayscale(100%);
        background-color: rgba(245, 245, 245, 0.5);
    }

    .upcoming_row {
        color: $inactive-grey;
        pointer-events: none;
        filter: grayscale(70%);
        background-color: rgba(245, 245, 245, 0.5);
    }

    .status {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .active_green {
        color: $active-green;
    }

    .pending_orange {
        color: $pending-orange;
    }

    .inactive_grey {
        color: $inactive-grey;
    }

    .activation {
        display: flex;
        flex-direction: column;
    }
    .created_at {
        line-height: 20px;
    }

    .reissued_at {
        font-style: italic;
    }

    .deal {
        padding: 12px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .deal_info {
            display: flex;
            flex-direction: column;
            padding-left: 12px;

            .promo_title {
                padding-bottom: 4px;
                font-family: 'Gotham-Medium';
            }

            .active_promo_title {
                color: black;
            }
        }

        img {
            height: 40px;
            width: 40px;
            border-radius: 4px;
        }
    }

    :global .ant-table-thead .ant-table-cell {
        padding: 12px 15px;
        line-height: 14px;
        font-size: 12px;
    }

    :global .ant-table-tbody {
        font-family: 'Gotham-Book';
        line-height: 13px;
        font-size: 13px;
        padding: 0 !important;
    }

    :global .ant-table-cell {
        text-transform: capitalize;
    }

    :global .ant-table-tbody > tr > td {
        padding: 0 15px !important;
    }

    .reissue {
        display: flex;
        justify-content: center;
    }

    .reissue_button {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        border: none;

        &:hover {
            background-color: #dfe0e1;
            cursor: pointer;
        }

        svg path {
            stroke: #4c5763;
        }
    }

    :global .ant-select-selector {
        min-width: 110px;
        height: 32px;
        border-radius: 6px !important;
        border: none !important;
        font-family: 'Gotham-Book';
        color: #1d2939;
        background-color: #f3f4f5 !important;
    }

    :global .ant-select-selection-item {
        line-height: 32px !important;
    }

    :global
        .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }
}

.selector_container {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
}

.filters_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-left: 27px;
    padding-right: 27px;
}

.no_coupons {
    font-family: 'Gotham-Black';
    color: black;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 200px;
    width: 100%;
    padding: 25px;
}

:global .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(218, 41, 28, 0.1) !important;
}

.spinner_container {
    min-height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active_circle {
    width: 8px;
    height: 8px;
    margin-right: 7px;
}
