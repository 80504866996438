@import '../../App.scss';

:global .ant-table-thead > tr > th {
    text-transform: uppercase;
    color: #313131;
    letter-spacing: 0.2em;
    font-size: 0.8em;
    font-weight: 600;
}
:global .text_center {
    text-align: center;
}
:global .vr {
    border-left: 1px solid #d6d6d6;
    min-height: 10px;
    margin: -3px 0 0 50%;
}
:global .inline_block {
    display: inline-block;
}

.date_status {
    color: $greyish-brown;
    text-transform: capitalize;
}

.title {
    font-family: Gotham-Book;
    font-size: 13px;
    min-width: 200px;
}

.activations_count {
    font-family: Gotham-Book-Italic;
    font-size: 11px;
    margin-top: 10px;
}

.selects_row {
    :global .ant-select,
    :global .ant-input-affix-wrapper {
        margin-right: 16px;
        margin-bottom: 16px;
        width: 200px;
    }
}

.switch_row {
    display: flex;
    padding: 12px 4px;
    align-items: center;
}

.other_options {
    p:hover {
        cursor: pointer;
    }

    p:last-child {
        margin-bottom: 0;
    }

    .delete_link {
        transition: color 0.25s;
    }

    .delete_link:hover {
        color: tomato;
    }
}

.pagination {
    margin-top: 20px;
}

.table {
    //min-width: 800px;
}
