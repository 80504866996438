@import '../../../../App.scss';

.container {
    width: 100%;
}

.input_row {
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 1.2rem;
    padding: 0.1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.label_with_tooltip {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
