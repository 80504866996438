@import '../../App.scss';

.text_input {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 0.25rem;
    &:focus {
        border-color: $couchetard-red;
        outline: none;
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2);
    }
}
