@import '../../../../App.scss';

$primary-color: #da291c;
$hover-color: #ff7875;
$spacing-small: 1rem;
$spacing-medium: 1rem;
$spacing-large: 1.25rem;
$font-family-bold: 'Gotham-Bold';

.winnable_container {
    margin-top: $spacing-large;
}

.input_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $spacing-small;
}

.fluid_input_col {
    margin-right: $spacing-medium;
    width: 100%;
}

.left_label {
    display: inline-block;
    margin-right: 0.75rem;
    font-family: $font-family-bold;
}

.winnable_buttons_row {
    display: flex;
    justify-content: flex-start;
    gap: 0.625rem;
    margin-top: $spacing-small;
}

.red_button {
    background-color: $primary-color;
    border-color: $primary-color;

    &:hover {
        background-color: $hover-color;
        border-color: $hover-color;
    }

    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
    }
}

.winners_box {
    margin-top: $spacing-large;
    min-width: 50rem;
}

.star_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.star_img {
    width: 2.5rem;
    height: 2.5rem;
}

.normal {
    position: absolute;
    color: #ffffff;
    font-size: 0.75rem;
    font-family: $font-family-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translateY(5%);
    pointer-events: none;
}

@media (max-width: $mobile-breakpoint) {
    .input_row {
        flex-direction: column;
        align-items: flex-start;
    }
}
