@import '../../App.scss';

.custom_option {
    display: flex;
    align-items: center;
}

.custom_margin {
    margin: 0 50px 0 130px;
}

.titles_holder {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 186px;
    box-sizing: border-box;
    padding-right: 6px;

    & > span {
        text-overflow: ellipsis !important;
        display: block;
        box-sizing: border-box;
        overflow: hidden;
    }
}

.image_holder_bg {
    img {
        background: rgba(0, 0, 0, 0.1);
    }
}

.fixed_width {
    width: 50%;
}

.item_padding {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    :global .ant-input {
        margin: 4px 0;
    }
}

.card_container {
    margin: 10px;
}

.viewer_split {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1300px) {
    .viewer_split {
        flex-direction: column;
    }
}

.image_field {
    margin: 0 20px;
}
