.info_banner {
    margin-bottom: 1rem;
}

.info_banner_message {
    font-weight: bold;
}

.info_banner_description {
    margin-top: 1rem;
}
