.spinnerContainer {
    text-align: center;
    padding: 1.5rem;
}

.actionContainer {
    text-align: right;
    margin-top: 1.5rem;
}

.button {
    padding: 0.3125rem 0.5rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.25rem;
    font-family: Gotham-Bold;
    font-size: 0.8125rem;
    line-height: 0.8125rem;
    background-color: #da291c;
    color: white;
    &:hover {
        cursor: pointer;
        background-color: #b71c13;
    }
}

.closeButton {
    background-color: #da291c;
    &:hover {
        background-color: #b71c13;
    }
}

.swapButton {
    background-color: #da291c;
    &:hover {
        background-color: #b71c13;
    }
    &:focus {
        background-color: #da291c;
    }
}
