.search {
    :global .ant-input-affix-wrapper {
        width: 400px;
        height: 32px;
        border-radius: 6px;
        background-color: #f3f4f5;
        border: none;
    }

    :global .ant-input-affix-wrapper > input.ant-input {
        background-color: #f3f4f5;
    }

    :global .ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }

    :global .ant-input {
        font-family: 'Gotham-Book';
        font-size: 13px;
    }

    :global .ant-input-prefix:hover {
        cursor: pointer;
        svg path {
            stroke: black;
        }
    }

    :global .ant-input-prefix {
        margin-right: 15px;
    }

    :global .ant-input-affix-wrapper:hover {
        box-shadow: 0 0 0 2px rgba(218, 41, 28, 0.2) !important;
    }
}
