@import '../../App.scss';

.toptabs_container {
    :global .ant-tabs-tab-active {
        color: $couchetard-red !important;
    }

    :global .ant-tabs-tab:hover {
        color: $couchetard-red !important;
    }

    :global .ant-tabs-ink-bar {
        background-color: $couchetard-red !important;
    }

    :global .ant-tabs-bar {
        margin: 0 0 0 0 !important;
        border-bottom: 1px solid #eaecf0 !important;
    }

    :global .ant-tabs.ant-tabs-top.ant-tabs-line {
        padding-top: 10px !important;
    }

    :global .ant-tabs {
        color: #56616d;
        &-nav {
            display: flex;

            .ant-tabs-tab {
                flex-grow: 1;
                margin-right: 0px;
                width: 90px;
                text-align: center;
                font-family: 'Gotham-Medium';
                padding-top: 0px;
            }
        }
    }

    :global .ant-tabs-nav-container {
        margin-left: 12px;
    }
}
