@import '../../../../App.scss';
.stats_row {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.5rem 0;
}

.stat_card {
    flex: 1;
    min-width: 8.125rem;
    max-width: 8.125rem;
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 0.25rem;
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
    padding: 0.3125rem;
    margin: 0;
}
