.container {
    padding: 10px;
    position: relative;
    height: 250px;
}

.card {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%; //320px;
    height: 100%; //135px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card:hover {
    cursor: pointer;
}

.image {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.text_grid {
    position: relative;
    display: grid;
    height: 100px;
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 15px;
    font-family: 'Gotham-Book';

    #title {
        color: black;
        font-weight: bold;
    }

    #subtitle {
        font-size: smaller;
        color: grey;
        line-height: 1.25em;
    }

    #dates {
        font-size: smaller;
        color: grey;
        line-height: 1.25em;
    }
}
