.featured_item {
    border-radius: 25px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title_holder {
    width: 200px;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: row;
}

.columns_styling {
    padding: 0 30px;
    flex-grow: 1;
    width: 33%;
}

.featured {
    background-color: white;
    border-radius: 25px;
}

.coupons {
    display: flex;
    justify-items: center;
}

.input_row {
    display: flex;
    padding: 12px 4px;
    align-items: top;
}
