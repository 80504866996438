@import '../../App.scss';

.promo_offer {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: #ffffff;
    font-family: Gotham-Medium;
    border-radius: 50%;

    :nth-child(1) {
        font-size: 20px;
    }

    b {
        margin: 0 0;
    }

    p {
        margin: 0 0;
    }
}

.query_box {
    max-width: 400px;
    margin: 20px 0;
}
