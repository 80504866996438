.statsBox {
    background-color: white;
    padding: 0.75em 0.9375em;
    width: 14.125em;
    height: 9.375em;
    text-align: left;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 0.15em;
}

.title {
    font-weight: bold;
    font-size: 0.85em;
    margin-bottom: 0.375em;
    display: flex;
    align-items: center;

    .icon_wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-right: 0.375em;
    }

    svg {
        width: 1.1em;
        height: auto;
    }
}

.value {
    font-size: 2.325em;
    font-weight: bold;
    margin: auto 0;
}

.difference {
    font-size: 0.725em;
    margin-top: 0.125em;
    display: inline-flex;
    align-items: center;
}

.arrowCircle {
    border-radius: 50%;
    width: 1.125em;
    height: 1.125em;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.differenceUp {
    color: green;
}

.differenceUp .arrowCircle {
    background-color: #ddfef1;
}

.differenceDown {
    color: red;
}

.differenceDown .arrowCircle {
    background-color: #fedddd;
}
