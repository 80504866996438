.container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 13px;
    padding-top: 17.5px;
    font-family: 'Gotham-Book';
    font-size: 13px;
    line-height: 19px;

    .title {
        display: flex;
        font-weight: bold;
        color: black;
        min-width: 40%;
        svg {
            margin-right: 8.5px;
        }
    }

    .info {
        color: #56616d;
        width: 60%;
        overflow-wrap: break-word;
    }

    .info_and_button {
        color: #56616d;
        width: 34%;
        overflow-wrap: break-word;
    }

    .button {
        padding: 5px 8px 5px 8px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        color: black;
        font-size: 13px;
        line-height: 13px;
        background-color: #f3f4f5;
        &:hover {
            cursor: pointer;
            background-color: lightgray;
        }
    }
}
