.page_title {
    font-family: 'Gotham-Black';
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    padding-left: 16px;
    display: flex;
    align-items: center;
    line-height: 13px;
    font-size: 13px;

    margin-bottom: 0;
}
.page_title_container {
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid #e6e6e6;
}
